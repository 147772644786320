import { createWebHistory } from 'vue-router'

// ---------- DASHBOARD ----------

const PageDashboard = () => import(
  '@extranet/components/pages/PageDashboard.vue'
)

// ---------- AUTHENTICATION ----------

const BaseAuth = () => import(
  '@extranet/components/pages/auth/BaseAuth.vue'
)
const PageLogin = () => import(
  '@extranet/components/pages/auth/PageLogin.vue'
)
const PageRegister = () => import(
  '@extranet/components/pages/auth/PageRegister.vue'
)
const PagePasswordForgotten = () => import(
  '@extranet/components/pages/auth/PagePasswordForgotten.vue'
)
const PagePasswordReset = () => import(
  '@extranet/components/pages/auth/PagePasswordReset.vue'
)
const PageCredentialVerification = () => import(
  '@extranet/components/pages/auth/PageCredentialVerification.vue'
)

// ---------- ERRORS ----------

const PageNotFound = () => import(
  '@extranet/components/pages/errors/PageNotFound.vue'
)

// ---------- INSTITUTION ----------

const BaseInstitution = () => import(
  '@extranet/components/pages/institutions/BaseInstitution.vue'
)
const PageInstitutionsDashboard = () => import(
  '@extranet/components/pages/institutions/PageInstitutionsDashboard.vue'
)

// ---------- TRAVELLERS ----------

const PageTravellersDashboard = () => import(
  '@extranet/components/pages/travellers/PageTravellersDashboard.vue'
)

// ---------- FOLDERS ----------

const BaseTravellerFolder = () => import(
  '@extranet/components/pages/traveller_folders/BaseTravellerFolder.vue'
)
const PageTravellerFoldersShow = () => import(
  '@extranet/components/pages/traveller_folders/PageTravellerFoldersShow.vue'
)
const PageTravellerFoldersCreate = () => import(
  '@extranet/components/pages/traveller_folders/PageTravellerFoldersCreate.vue'
)
const PageTravellerFoldersEdit = () => import(
  '@extranet/components/pages/traveller_folders/PageTravellerFoldersEdit.vue'
)

// ---------- USERS ----------

const BaseUser = () => import(
  '@extranet/components/pages/users/BaseUser.vue'
)
const PageUsersIndex = () => import(
  '@extranet/components/pages/users/PageUsersIndex.vue'
)
const PagePrivateProfileShow = () => import(
  '@extranet/components/pages/users/PagePrivateProfileShow.vue'
)
const PagePrivateProfileEdit = () => import(
  '@extranet/components/pages/users/PagePrivateProfileEdit.vue'
)
const PagePrivateProfileDelete = () => import(
  '@extranet/components/pages/users/PagePrivateProfileDelete.vue'
)
const PagePublicProfileShow = () => import(
  '@extranet/components/pages/users/PagePublicProfileShow.vue'
)
const PageCredentialEdit = () => import(
  '@extranet/components/pages/users/PageCredentialEdit.vue'
)

// ---------- POINTS OF INTEREST ----------

const BasePointOfInterest = () => import(
  '@extranet/components/pages/points_of_interest/BasePointOfInterest.vue'
)
const PagePointsOfInterestIndex = () => import(
  '@extranet/components/pages/points_of_interest/PagePointsOfInterestIndex.vue'
)
const PagePointsOfInterestShow = () => import(
  '@extranet/components/pages/points_of_interest/PagePointsOfInterestShow.vue'
)
const PagePointsOfInterestCreate = () => import(
  '@extranet/components/pages/points_of_interest/PagePointsOfInterestCreate.vue'
)
const PagePointsOfInterestEdit = () => import(
  '@extranet/components/pages/points_of_interest/PagePointsOfInterestEdit.vue'
)
const PagePointsOfInterestClaim = () => import(
  '@extranet/components/pages/points_of_interest/PagePointsOfInterestClaim.vue'
)

// ---------- EXCURSIONS ----------

const BaseExcursion = () => import(
  '@extranet/components/pages/excursions/BaseExcursion.vue'
)
const PageExcursionsIndex = () => import(
  '@extranet/components/pages/excursions/PageExcursionsIndex.vue'
)
const PageExcursionsCreate = () => import(
  '@extranet/components/pages/excursions/PageExcursionsCreate.vue'
)
const PageExcursionsShow = () => import(
  '@extranet/components/pages/excursions/PageExcursionsShow.vue'
)
const PageExcursionsEdit = () => import(
  '@extranet/components/pages/excursions/PageExcursionsEdit.vue'
)

// ---------- RESERVATIONS ----------

const BaseReservation = () => import(
  '@extranet/components/pages/reservations/BaseReservation.vue'
)
const PageReservationsShow = () => import(
  '@extranet/components/pages/reservations/PageReservationsShow.vue'
)
const PageReservationsCreate = () => import(
  '@extranet/components/pages/reservations/PageReservationsCreate.vue'
)
const PageReservationsEdit = () => import(
  '@extranet/components/pages/reservations/PageReservationsEdit.vue'
)

// ---------- NOTIFICATIONS ----------

const BaseNotification = () => import(
  '@extranet/components/pages/notifications/BaseNotification.vue'
)
const PageNotificationsIndex = () => import(
  '@extranet/components/pages/notifications/PageNotificationsIndex.vue'
)

// ---------- NOTIFICATIONS SETTINGS ----------

const BaseNotificationSetting = () => import(
  '@extranet/components/pages/notification_settings/BaseNotificationSetting.vue'
)
const PageNotificationSettingsEdit = () => import(
  '@extranet/components/pages/notification_settings/PageNotificationSettingsEdit.vue'
)

// ---------- FINANCES ----------

const BaseFinance = () => import(
  '@extranet/components/pages/finances/BaseFinance.vue'
)
const PageFinancesDashboard = () => import(
  '@extranet/components/pages/finances/PageFinancesDashboard.vue'
)

// ---------- INVITATIONS ----------

const BaseInvitation = () => import(
  '@extranet/components/pages/invitations/BaseInvitation.vue'
)
const PageInvitationsDashboard = () => import(
  '@extranet/components/pages/invitations/PageInvitationsDashboard.vue'
)

// ---------- FAQS ----------

const BaseFaq = () => import(
  '@extranet/components/pages/faq/BaseFaq.vue'
)
const PageFaqIndex = () => import(
  '@extranet/components/pages/faq/PageFaqIndex.vue'
)

// ---------- CHANGELOGS ----------

const BaseChangelog = () => import(
  '@extranet/components/pages/changelogs/BaseChangelog.vue'
)
const PageChangelogsIndex = () => import(
  '@extranet/components/pages/changelogs/PageChangelogsIndex.vue'
)
const PageChangelogsShow = () => import(
  '@extranet/components/pages/changelogs/PageChangelogsShow.vue'
)

// ---------- TERMS AND CONDITIONS ----------

const BaseTermsCondition = () => import(
  '@extranet/components/pages/terms_condition/BaseTermsCondition.vue'
)
const PageTermsConditionIndex = () => import(
  '@extranet/components/pages/terms_condition/PageTermsConditionIndex.vue'
)

// ---------- PRIVACY POLICY ----------

const BasePrivacyPolicy = () => import(
  '@extranet/components/pages/privacy_policy/BasePrivacyPolicy.vue'
)
const PagePrivacyPolicyIndex = () => import(
  '@extranet/components/pages/privacy_policy/PagePrivacyPolicyIndex.vue'
)

// ---------- CONTACTS ----------

const BaseContact = () => import(
  '@extranet/components/pages/contacts/BaseContact.vue'
)
const PageContactsIndex = () => import(
  '@extranet/components/pages/contacts/PageContactsIndex.vue'
)

// ---------- PARTNERS ----------

const BasePartner = () => import(
  '@extranet/components/pages/partners/BasePartner.vue'
)
const PagePartnersPartners = () => import(
  '@extranet/components/pages/partners/PagePartnersPartners.vue'
)
const PagePartnersCustomers = () => import(
  '@extranet/components/pages/partners/PagePartnersCustomers.vue'
)
const PagePartnersShow = () => import(
  '@extranet/components/pages/partners/PagePartnersShow.vue'
)

// ---------- INTERACTIONS ----------

const BaseInteraction = () => import(
  '@extranet/components/pages/interactions/BaseInteraction.vue'
)
const PageInteractionsIndex = () => import(
  '@extranet/components/pages/interactions/PageInteractionsIndex.vue'
)

// ---------- SUPPORT REQUESTS ----------

const BaseSupportRequest = () => import(
  '@extranet/components/pages/support_requests/BaseSupportRequest.vue'
)
const PageSupportRequestsIndex = () => import(
  '@extranet/components/pages/support_requests/PageSupportRequestsIndex.vue'
)
const PageSupportRequestsShow = () => import(
  '@extranet/components/pages/support_requests/PageSupportRequestsShow.vue'
)
const PageSupportRequestsCreate = () => import(
  '@extranet/components/pages/support_requests/PageSupportRequestsCreate.vue'
)

// ---------- SUPPORT MESSAGES ----------

const PageSupportMessagesCreate = () => import(
  '@extranet/components/pages/support_messages/PageSupportMessagesCreate.vue'
)

// ---------- PRIVACY SETTINGS ----------

const BasePrivacySetting = () => import(
  '@extranet/components/pages/privacy_settings/BasePrivacySetting.vue'
)
const PagePrivacySettingsEdit = () => import(
  '@extranet/components/pages/privacy_settings/PagePrivacySettingsEdit.vue'
)

// ---------- CLAIMS ----------

const BaseClaim = () => import(
  '@extranet/components/pages/claims/BaseClaim.vue'
)
const PageClaimsIndex = () => import(
  '@extranet/components/pages/claims/PageClaimsIndex.vue'
)
const PageClaimsShow = () => import(
  '@extranet/components/pages/claims/PageClaimsShow.vue'
)
const PageClaimsEdit = () => import(
  '@extranet/components/pages/claims/PageClaimsEdit.vue'
)

// ---------- INSTITUTIONS EMPLOYEES ----------

const BaseInstitutionsEmployees = () => import(
  '@extranet/components/pages/institutions_employees/BaseInstitutionEmployee.vue'
)
const PageInstitutionsEmployeesIndex = () => import(
  '@extranet/components/pages/institutions_employees/PageInstitutionsEmployeesIndex.vue'
)
const PageInstitutionsEmployeesInvite = () => import(
  '@extranet/components/pages/institutions_employees/PageInstitutionsEmployeesInvite.vue'
)

// ---------- COOKIES ----------

const BaseCookie = () => import(
  '@extranet/components/pages/cookies/BaseCookie.vue'
)
const PageCookiesShow = () => import(
  '@extranet/components/pages/cookies/PageCookiesShow.vue'
)

// ---------- CREDITS ----------

const BaseCredit = () => import(
  '@extranet/components/pages/credit/BaseCredit.vue'
)
const PageCreditIndex = () => import(
  '@extranet/components/pages/credit/PageCreditIndex.vue'
)

// ---------- SUBSCRIPTIONS ----------

const PageSubscriptions = () => import(
  '@extranet/components/pages/subscriptions/PageSubscriptions.vue'
)

// ---------- TRAVELLER EMAIL ----------

const PageTravellerEmailsDashboard = () => import(
  '@extranet/components/pages/traveller_emails/PageTravellerEmailsDashboard.vue'
)

// ------------ BROADCAST ----------

const PageBroadcastPageEdit = () => import(
  '@extranet/components/pages/broadcast/PageBroadcastPageEdit.vue'
)

const PageBroadcastPageCreate = () => import(
  '@extranet/components/pages/broadcast/PageBroadcastPageCreate.vue'
)

export default {
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'root',
      redirect: { name: 'dashboard' },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: PageDashboard,
    },
    {
      path: '/auth',
      redirect: { name: 'dashboard' },
      component: BaseAuth,
      children: [
        {
          path: 'login',
          name: 'login',
          component: PageLogin,
        },
        {
          path: 'register',
          name: 'register',
          component: PageRegister,
        },
        {
          path: 'password-forgotten',
          name: 'password-forgotten',
          component: PagePasswordForgotten,
        },
        {
          path: 'password-reset/:uuid',
          name: 'password-reset',
          component: PagePasswordReset,
        },
        {
          path: 'credential-verification',
          name: 'credential-verification',
          component: PageCredentialVerification,
        },
      ],
    },
    {
      path: '/institutions',
      component: BaseInstitution,
      children: [
        {
          path: '',
          name: 'institutions',
          component: PageInstitutionsDashboard,
        },
      ],
    },
    {
      path: '/travellers',
      name: 'travellers',
      component: PageTravellersDashboard,
    },
    {
      path: '/folders',
      component: BaseTravellerFolder,
      children: [
        {
          path: ':id(\\d+)',
          name: 'show-traveller-folders',
          component: PageTravellerFoldersShow,
        },
        {
          path: 'create',
          name: 'create-traveller-folders',
          component: PageTravellerFoldersCreate,
        },
        {
          path: ':id(\\d+)/edit',
          name: 'edit-traveller-folders',
          component: PageTravellerFoldersEdit,
        },
      ],
    },
    {
      path: '/profile',
      component: BaseUser,
      children: [
        {
          path: '',
          name: 'show-profile',
          component: PagePrivateProfileShow,
        },
        {
          path: 'edit',
          name: 'edit-profile',
          component: PagePrivateProfileEdit,
        },
        {
          path: 'edit-credential',
          name: 'edit-credential',
          component: PageCredentialEdit,
        },
        {
          path: 'delete',
          name: 'delete-profile',
          component: PagePrivateProfileDelete,
        },
      ],
    },
    {
      path: '/subscriptions',
      name: 'subscriptions',
      component: PageSubscriptions,
    },
    {
      path: '/users',
      component: BaseUser,
      children: [
        {
          path: '',
          name: 'index-users',
          component: PageUsersIndex,
        },
        {
          path: ':id(\\d+)',
          name: 'show-users',
          component: PagePublicProfileShow,
        },
      ],
    },
    {
      path: '/points-of-interest',
      component: BasePointOfInterest,
      children: [
        {
          path: '',
          name: 'index-points-of-interest',
          component: PagePointsOfInterestIndex,
        },
        {
          path: 'create',
          name: 'create-points-of-interest',
          component: PagePointsOfInterestCreate,
        },
        {
          path: ':id(\\d+)',
          name: 'show-points-of-interest',
          component: PagePointsOfInterestShow,
        },
        {
          path: ':id(\\d+)/edit',
          name: 'edit-points-of-interest',
          component: PagePointsOfInterestEdit,
        },
        {
          path: ':id(\\d+)/claim',
          name: 'claim-points-of-interest',
          component: PagePointsOfInterestClaim,
        },
      ],
    },
    {
      path: '/excursions',
      component: BaseExcursion,
      children: [
        {
          path: '',
          name: 'index-excursions',
          component: PageExcursionsIndex,
        },
        {
          path: 'create',
          name: 'create-excursions',
          component: PageExcursionsCreate,
        },
        {
          path: ':id(\\d+)',
          name: 'show-excursions',
          component: PageExcursionsShow,
        },
        {
          path: ':id(\\d+)/edit',
          name: 'edit-excursions',
          component: PageExcursionsEdit,
        },
      ],
    },
    {
      path: '/reservations',
      component: BaseReservation,
      children: [
        {
          path: 'create',
          name: 'create-reservations',
          component: PageReservationsCreate,
        },
        {
          path: ':id(\\d+)',
          name: 'show-reservations',
          component: PageReservationsShow,
        },
        {
          path: ':id(\\d+)/edit',
          name: 'edit-reservations',
          component: PageReservationsEdit,
        },
      ],
    },
    {
      path: '/notifications',
      component: BaseNotification,
      children: [
        {
          path: '',
          name: 'index-notifications',
          component: PageNotificationsIndex,
        },
      ],
    },
    {
      path: '/notification-settings',
      component: BaseNotificationSetting,
      children: [
        {
          path: 'edit',
          name: 'edit-notification-settings',
          component: PageNotificationSettingsEdit,
        },
      ],
    },
    {
      path: '/finances',
      component: BaseFinance,
      children: [
        {
          path: '',
          name: 'finances',
          component: PageFinancesDashboard,
        },
      ],
    },
    {
      path: '/invitations',
      component: BaseInvitation,
      children: [
        {
          path: '',
          name: 'invitations',
          component: PageInvitationsDashboard,
        },
      ],
    },
    {
      path: '/faq',
      component: BaseFaq,
      children: [
        {
          path: '',
          name: 'faq',
          component: PageFaqIndex,
        },
      ],
    },
    {
      path: '/changelogs',
      component: BaseChangelog,
      children: [
        {
          path: '',
          name: 'index-changelogs',
          component: PageChangelogsIndex,
        },
        {
          path: ':id(\\d+)',
          name: 'show-changelogs',
          component: PageChangelogsShow,
        },
      ],
    },
    {
      path: '/terms-condition',
      component: BaseTermsCondition,
      children: [
        {
          path: '',
          name: 'index-terms-condition',
          component: PageTermsConditionIndex,
        },
      ],
    },
    {
      path: '/privacy-policy',
      component: BasePrivacyPolicy,
      children: [
        {
          path: '',
          name: 'index-privacy-policy',
          component: PagePrivacyPolicyIndex,
        },
      ],
    },
    {
      path: '/contacts',
      component: BaseContact,
      children: [
        {
          path: '',
          name: 'index-contacts',
          component: PageContactsIndex,
        },
      ],
    },
    {
      path: '/partners',
      component: BasePartner,
      children: [
        {
          path: '',
          name: 'index-partners',
          component: PagePartnersPartners,
        },
        {
          path: ':id(\\d+)',
          name: 'show-partners',
          component: PagePartnersShow,
        },
      ],
    },
    {
      path: '/customers',
      component: BasePartner,
      children: [
        {
          path: '',
          name: 'index-customers',
          component: PagePartnersCustomers,
        },
      ],
    },
    {
      path: '/interactions',
      component: BaseInteraction,
      children: [
        {
          path: '',
          name: 'index-interactions',
          component: PageInteractionsIndex,
        },
      ],
    },
    {
      path: '/privacy-settings',
      component: BasePrivacySetting,
      children: [
        {
          path: 'edit',
          name: 'edit-privacy-settings',
          component: PagePrivacySettingsEdit,
        },
      ],
    },
    {
      path: '/support-requests',
      component: BaseSupportRequest,
      children: [
        {
          path: '',
          name: 'index-support-requests',
          component: PageSupportRequestsIndex,
        },
        {
          path: ':id(\\d+)',
          name: 'show-support-requests',
          component: PageSupportRequestsShow,
        },
        {
          path: 'create',
          name: 'create-support-requests',
          component: PageSupportRequestsCreate,
        },
        {
          path: ':id(\\d+)/create',
          name: 'create-support-messages',
          component: PageSupportMessagesCreate,
        },
      ],
    },
    {
      path: '/claims',
      component: BaseClaim,
      children: [
        {
          path: '',
          name: 'index-claims',
          component: PageClaimsIndex,
        },
        {
          path: ':id(\\d+)',
          name: 'show-claims',
          component: PageClaimsShow,
        },
        {
          path: ':id(\\d+)/edit',
          name: 'edit-claims',
          component: PageClaimsEdit,
        },
      ],
    },
    {
      path: '/institutions-employees',
      component: BaseInstitutionsEmployees,
      children: [
        {
          path: '',
          name: 'index-institutions-employees',
          component: PageInstitutionsEmployeesIndex,
        },
        {
          path: 'invite',
          name: 'invite-institutions-employees',
          component: PageInstitutionsEmployeesInvite,
        },
      ],
    },
    {
      path: '/cookies',
      component: BaseCookie,
      children: [
        {
          path: '',
          name: 'show-cookies',
          component: PageCookiesShow,
        },
      ],
    },
    {
      path: '/credits',
      component: BaseCredit,
      children: [
        {
          path: '',
          name: 'index-credits',
          component: PageCreditIndex,
        },
      ],
    },
    {
      path: '/traveller-emails',
      name: 'traveller-emails',
      component: PageTravellerEmailsDashboard,
    },
    {
      path: '/broadcast-page/create/:poi_id',
      name: 'create-broadcast-page',
      component: PageBroadcastPageCreate,
    },
    {
      path: '/broadcast-page/:id/edit',
      name: 'update-broadcast-page',
      component: PageBroadcastPageEdit,
    },
    // Keep it at the end because
    // it's a fallback route
    {
      path: '/:pathMatch(.*)*',
      name: 'PageNotFound',
      component: PageNotFound,
    },
  ],
}
